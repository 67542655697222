import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='When it comes to precious gemstones and diamonds, it is necessary to be precise and exhaustive. 
          This is why Gemmoprice was thought to be an essential tool for the fine jewelry sector. 
          Fill out the form below by entering your data and clarifying your need.
          A member of our team will take charge of your request and provide you with an answer within a maximum of 24 hours.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
